html,
body {
  height: 100%;
  width: 100%;
  min-width: 100vw;
  max-width: 100%;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  box-sizing: border-box;
}

#root {
  height: 100%;
  max-height: 100%;
}
